import { httpApi } from "@/services/httpApi";
class apiClientRemarks {
  async index(clientId) {
    return await httpApi.get(`beetle/v1/clients/${clientId}/remarks`);
  }
  async store(clientId, data) {
    return await httpApi.post(`beetle/v1/clients/${clientId}/remarks`, data);
  }
  async delete(clientId, remarkId) {
    return await httpApi.delete(
      `beetle/v1/clients/${clientId}/remarks/${remarkId}`
    );
  }
}
export default new apiClientRemarks();
