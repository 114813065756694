<template>
  <div class="mt-4 row">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="setClientActivity(1)">
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4 mt-3">
                    <label for="filter-keyword">Search</label>
                    <input
                      id="filter-keyword"
                      v-model="filter.keyword"
                      type="search"
                      class="form-control"
                      placeholder="Search"
                    />
                  </div>
                  <div class="col-12 mt-3">
                    <argon-button
                      class="mt-2 mb-0 ms-2"
                      color="success"
                      variant="gradient"
                      size="sm"
                      >Filter
                    </argon-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <timeline-list>
            <timeline-item
              v-for="(el, index) in clientActivity"
              :key="index"
              :icon="{
                component: 'ni ni-check-bold',
                color: 'success',
              }"
              :title="el.event"
              :date-time="dateFormat(el.created_at, 'dateTime')"
              :description="el.description"
              :badges="[{ title: el['causer_name'], color: 'success' }]"
            />
            <loading-spinner :loading="loading" />
            <div v-if="showLoadMore && !loading" class="text-center mt-3">
              <argon-button color="primary" size="sm" @click="loadMore">
                Load More
              </argon-button>
            </div>
          </timeline-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { showMessage } from "@/assets/js/show-message";
import apiClient from "@/services/apiClient";
import TimelineList from "@/examples/Cards/TimelineList.vue";
import TimelineItem from "@/examples/Cards/TimelineItem.vue";
import { dateFormat } from "@/lib/dateHelper";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ArgonButton from "@/components/ArgonButton.vue";
export default {
  name: "ClientActivity",
  components: {
    ArgonButton,
    TimelineList,
    TimelineItem,
    LoadingSpinner,
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      clientActivity: [],
      filter: {
        keyword: "",
      },
      page: 1,
      length: 50,
      showLoadMore: true,
      draw: 1,
    };
  },
  async mounted() {
    await this.setClientActivity(1);
  },
  methods: {
    dateFormat,

    async setClientActivity(page) {
      if (page) {
        this.page = page;
        this.clientActivity = [];
        this.showLoadMore = true;
      }
      this.loading = true;
      const payload = apiClient.activityLogsSearchOptions(
        (this.page - 1) * this.length,
        this.length,
        this.filter.keyword
      );
      payload.draw = this.draw;
      this.draw += 1;
      const response = await apiClient.activityLogs(this.clientId, payload);

      if (response.status == 200) {
        const { data, recordsTotal } = response.data;
        this.clientActivity = [...this.clientActivity, ...data];
        if (
          data.length === 0 ||
          data.length < this.length ||
          this.clientActivity.length >= recordsTotal
        ) {
          this.showLoadMore = false;
        }
      } else {
        this.clientActivity = [];
        showMessage(response.message, "error");
      }
      this.loading = false;
    },
    async loadMore() {
      this.page += 1;
      await this.setClientActivity();
    },
  },
};
</script>
