<template>
  <div v-if="filteredInactiveMeals.length" class="card mt-4">
    <div class="card-header pb-0 cursor-pointer" @click="toggleInactiveMeals">
      <h5 class="mb-3 d-flex align-items-center text-primary">
        Inactive Meals
        <i
          class="fas ms-3"
          :class="showInactiveMeals ? 'fa-chevron-up' : 'fa-chevron-down'"
        ></i>
      </h5>
    </div>
    <div v-show="showInactiveMeals" class="card-body pt-0 row">
      <div
        v-for="(inactiveMeal, index) in filteredInactiveMeals"
        :key="index"
        class="col-12 col-lg-4 col-md-6 mb-3"
      >
        <div v-for="(meal, mealIndex) in inactiveMeal.meals" :key="mealIndex">
          <order-meal-item
            :delivery-date="inactiveMeal.date"
            :meal="meal"
            @edit-meal="editMeal"
            @delete-meal="deleteMeal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderMealItem from "./OrderMealItem.vue";
import { handleError, handleResponse } from "@/lib/helpers";
import apiMiscList from "@/services/apiMiscList";

export default {
  name: "OrderInactiveMealsCard",
  components: { OrderMealItem },
  props: {
    inactiveMeals: {
      type: Array,
      required: true,
    },
  },
  emits: ["editMenuItem", "deleteMenuItem"],
  data() {
    return {
      showInactiveMeals: false,
      nextAvailableStartDate: "2000-01-01",
    };
  },
  computed: {
    filteredInactiveMeals() {
      return this.inactiveMeals.filter((inactiveMeal) => {
        return inactiveMeal.date > this.nextAvailableStartDate;
      });
    },
  },
  mounted() {
    this.setNextAvailableStartDate();
  },
  methods: {
    async setNextAvailableStartDate() {
      try {
        const response = await apiMiscList.nextAvailableStartDate();
        const data = await handleResponse(response);
        this.nextAvailableStartDate = data.date || this.nextAvailableStartDate;
      } catch (error) {
        handleError(error);
      }
    },
    editMeal(data) {
      this.$emit("editMenuItem", data);
    },
    deleteMeal(meal) {
      this.$emit("deleteMenuItem", meal);
    },
    toggleInactiveMeals() {
      this.showInactiveMeals = !this.showInactiveMeals;
    },
  },
};
</script>
