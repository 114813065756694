<template>
  <div class="row mb-3 g-1" :class="{ 'opacity-5': !isVisible(menuItem) }">
    <div v-if="deliveryDate" class="col-12">
      <h6>Delivery Date: {{ dateFormat(deliveryDate) }}</h6>
    </div>
    <img
      v-for="(img, key) in menuItemImages"
      :key="key"
      alt=""
      :src="img.src"
      :class="`img-meal
            img-meal-${menuItemImages.length}
            ${img.type}`"
      @click="showModalObject(modals.productImage)"
    />

    <div class="col ms-4 mt-2">
      <div
        v-for="(item, index) of menuItem.dayPackMealComponents.data"
        :key="index"
      >
        <div class="numbers">
          <div>
            <span class="text-sm">
              {{
                `${item.productVariation.data["product.name"]} ${item.productVariation.data["weight"]}${item.productVariation.data["unit"]}`
              }}
            </span>
            <dislikes-allergens-icons :item="item" />
            <span class="fw-bold mx-1 text-sm">{{
              currencyFormat(item.productVariation.data["price"])
            }}</span>
            <span v-if="index == 0 && isVisible(menuItem) && showActionButtons">
              <span
                class="ms-2 cursor-pointer"
                @click="
                  $emit('editMenuItem', {
                    mealType: menuItem.meal_type,
                    actionType: 'Edit',
                    menuItem: menuItem,
                    mealId: menuItem.id,
                    dayPackId: menuItem.order_day_pack_id,
                    deliveryDate: deliveryDate,
                  })
                "
                ><i class="far fa-edit text-secondary"></i
              ></span>
              <span
                class="ms-2 cursor-pointer"
                @click="$emit('deleteMenuItem', menuItem)"
                ><i class="fas fa-trash text-secondary"></i
              ></span>
            </span>
            <span>
              <i
                v-if="index === 0 && mealIsInactive"
                class="ms-2 fas fa-exclamation-triangle text-warning"
                title="Inactive"
              ></i>
            </span>
          </div>
          <div>
            <span class="text-sm"
              >Calories: {{ item.productVariation.data.kcal }}</span
            >
            <span class="text-sm ms-1"
              >Carbs: {{ item.productVariation.data.carb }}</span
            >
            <span class="text-sm ms-1"
              >Fat: {{ item.productVariation.data.fat }}</span
            >
            <span class="text-sm ms-1"
              >Protein: {{ item.productVariation.data.pro }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <product-image-modal
      v-if="modals.productImage.show"
      :images="menuItemImages"
      :modal-id="modals.productImage.id"
      :title="menuItemTitle"
      @close="closeModalObject(modals.productImage)"
    />
  </div>
</template>

<script>
import { currencyFormat } from "@/lib/helpers";
import DislikesAllergensIcons from "@/components/DislikesAllergensIcons.vue";
import { mealIsInactive } from "@/lib/mealHelper";
import { dateFormat } from "@/lib/dateHelper";
import { closeModalObject, showModalObject } from "@/lib/bootstrap";
import ProductImageModal from "@/components/ProductImageModal.vue";

export default {
  name: "MenuItem",
  components: { ProductImageModal, DislikesAllergensIcons },
  props: {
    deliveryDate: {
      type: String,
      default: "",
    },
    menuItem: {
      type: Object,
      required: true,
    },
    mealType: {
      type: String,
      required: true,
    },
    showActionButtons: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["deleteMenuItem", "editMenuItem"],
  data() {
    return {
      modals: {
        productImage: {
          show: false,
          id: "product-image-modal",
        },
      },
    };
  },
  computed: {
    menuItemTitle() {
      return this.menuItem.dayPackMealComponents.data
        .map(
          (item) =>
            `${item.productVariation.data["product.name"]} ${item.productVariation.data["weight"]}${item.productVariation.data["unit"]}`
        )
        .join(", ");
    },
    menuItemImages() {
      const result = [];
      const meals = this.menuItem.dayPackMealComponents.data;
      if (this.menuItem.meal_type === "custom_meal") {
        const order = ["protein", "veg", "carb", "sauce"];
        const sortedMeals = {};
        for (let i in meals) {
          const meal = meals[i].productVariation.data;
          const type = meal["product.type"];
          if (type === "sauce") {
            continue;
          }
          if (order.includes(type)) {
            if (!sortedMeals[type]) {
              sortedMeals[type] = [];
            }
            sortedMeals[type].push({
              src: meal["product.app_image"],
              type: type,
            });
          }
        }

        order.forEach((type) => {
          if (sortedMeals[type]) {
            result.push(...sortedMeals[type]);
          }
        });
      } else {
        for (let i in meals) {
          const meal = meals[i].productVariation.data;
          const type = meal["product.type"];
          if (type === "sauce") {
            continue;
          }
          result.push({
            src: meal["product.app_image"],
            type: type,
          });
        }
      }
      return result;
    },
    mealIsInactive() {
      return mealIsInactive(this.menuItem.dayPackMealComponents.data);
    },
  },
  methods: {
    closeModalObject,
    showModalObject,
    dateFormat,
    currencyFormat,
    isVisible(menuItem) {
      return (
        typeof menuItem.isVisible === typeof undefined || menuItem.isVisible
      );
    },
  },
};
</script>
<style>
.images-container-width {
  width: 102px;
}
.img-meal {
  width: auto !important;
  padding: 0 !important;
  height: 50px;
}
</style>
