import { httpApi } from "@/services/httpApi";
import { buildQueryParams } from "@/lib/queryHelper";
class ApiOrderRefundRequests {
  async get(clientId, refundRequestId) {
    return await httpApi.get(
      `beetle/v1/clients/${clientId}/orders/refund-requests/${refundRequestId}`
    );
  }
  async list(clientId, options) {
    let baseUrl = `beetle/v1/clients/${clientId}/orders/refund-requests`;
    const queryParams = buildQueryParams(options);
    let responseType = options.action === "download" ? "blob" : "json";
    let url = `${baseUrl}?${queryParams.toString()}`;
    return await httpApi.get(url, { responseType: responseType });
  }
  async createOrderRefundRequest(clientId, orderId, data) {
    return await httpApi.post(
      `beetle/v1/clients/${clientId}/orders/${orderId}/create-refund-order`,
      data
    );
  }
  async createBagRefundRequest(clientId, orderId, data) {
    return await httpApi.post(
      `beetle/v1/clients/${clientId}/orders/${orderId}/create-refund-bag-deposit`,
      data
    );
  }
  async createBagDepositRefund(clientId, data) {
    return await httpApi.post(
      `beetle/v1/clients/${clientId}/create-bag-deposit-refund`,
      data
    );
  }
  async approve(clientId, refundRequestId, data) {
    return await httpApi.post(
      `beetle/v1/clients/${clientId}/orders/refund-requests/${refundRequestId}/approve`,
      data
    );
  }
  async decline(clientId, refundRequestId, data) {
    return await httpApi.post(
      `beetle/v1/clients/${clientId}/orders/refund-requests/${refundRequestId}/decline`,
      data
    );
  }
}

export default new ApiOrderRefundRequests();
