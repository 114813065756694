<template>
  <div>
    <loading-spinner
      :loading="loading || formSubmitted"
      :screen-center="true"
    />
    <form v-if="!loading">
      <div class="row">
        <div class="col-12 col-xl-4">
          <argon-select
            id="refund-request-form-payment-source"
            label="Payment Source"
            :model-value="form.payment_source"
            :errors="v$.form.payment_source.$errors"
            :options="{
              choices: paymentSourceChoices,
              searchEnabled: false,
            }"
            @update:model-value="form.payment_source = $event"
          />
        </div>
        <div v-if="!bagDepositRefund" class="col-12 col-xl-4">
          <argon-select
            id="refund-request-type"
            label="Type"
            :model-value="form.type"
            :errors="v$.form.type.$errors"
            :options="{
              choices: typeChoices,
              searchEnabled: false,
            }"
            @update:model-value="form.type = $event"
          />
        </div>
        <div v-show="requiresCancellationCharge" class="col-12 col-xl-4">
          <label>Cancellation charge percentage</label>
          <argon-input
            id="cancellation-charge-percent"
            type="text"
            placeholder=""
            :model-value="form.cancellation_charge_percent"
            :errors="v$.form.cancellation_charge_percent.$errors"
            @update:model-value="form.cancellation_charge_percent = $event"
          />
        </div>
      </div>
      <div v-show="requiresBankDetails" class="row">
        <div class="col-12 col-xl-4">
          <argon-select
            id="refund-request-meta-bank"
            label="Bank"
            :model-value="form.meta.bank.name"
            :errors="v$.form.meta.bank.name.$errors"
            :options="{
              choices: bankChoices,
            }"
            @update:model-value="form.meta.bank.name = $event"
          />
        </div>
        <div class="col-12 col-xl-4">
          <label>Bank account name</label>
          <argon-input
            id="refund-request-bank-account-name"
            type="text"
            placeholder="Enter bank account name"
            :model-value="form.meta.bank.account.name"
            :errors="v$.form.meta.bank.account.name.$errors"
            @update:model-value="form.meta.bank.account.name = $event"
          />
        </div>
        <div class="col-12 col-xl-4">
          <label>IBAN</label>
          <argon-input
            id="refund-request-bank-account-number"
            type="text"
            placeholder="Enter IBAN"
            :model-value="form.meta.bank.account.number"
            :errors="v$.form.meta.bank.account.number.$errors"
            @update:model-value="form.meta.bank.account.number = $event"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <argon-textarea
            id="refund-request-description"
            label="Description"
            placeholder=""
            :model-value="form.description"
            @update:model-value="form.description = $event"
          >
          </argon-textarea>
        </div>
        <div v-if="form.notes" class="col-12">
          <argon-textarea
            id="refund-request-description"
            label="Notes"
            placeholder=""
            :model-value="form.notes"
            @update:model-value="form.notes = $event"
          >
          </argon-textarea>
        </div>
      </div>
    </form>
    <div :class="footerClass">
      <submit-form-button
        v-if="!form.id"
        default-button-text="Save"
        default-class="btn btn-success me-3 "
        form-submitted-class="btn btn-dark me-3"
        :form-submitted="formSubmitted"
        @click="saveRefundRequest"
      />
      <button
        v-if="showCloseButton"
        type="button"
        class="btn btn-secondary"
        @click="$emit('close')"
      >
        Close
      </button>
    </div>
  </div>
</template>

<script>
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { validatorMessages, helpers } from "@/lib/validators";
import { required, numeric, requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import apiOrderRefundRequests from "@/services/apiOrderRefundRequests";
import { handleError, handleResponse } from "@/lib/helpers";
import { formatDataToChoicesJs } from "@/assets/js/init-choices";
import apiMiscList from "@/services/apiMiscList";
import ArgonSelect from "@/components/ArgonSelect.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
export default {
  name: "RefundRequestForm",
  components: {
    SubmitFormButton,
    LoadingSpinner,
    ArgonSelect,
    ArgonTextarea,
    ArgonInput,
  },
  props: {
    footerClass: {
      type: String,
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    invoicePaymentSource: {
      type: String,
      default: "",
    },
    clientId: {
      type: [String, Number],
      required: true,
    },
    bagDepositRefund: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: [String, Number],
      default: "",
      required: false,
    },
    initialData: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["saved", "close"],
  setup: () => ({
    v$: useVuelidate(),
  }),
  data() {
    return {
      loading: true,
      form: this.getDefaultRefundRequest(),
      formSubmitted: false,
      paymentSourceChoices: [],
      typeChoices: [],
      bankChoices: [],
    };
  },
  computed: {
    requiresBankDetails() {
      const requiresBankDetails = ["bank_deposit", "cheque"];
      return requiresBankDetails.includes(this.form.payment_source);
    },
    requiresCancellationCharge() {
      return this.form.type === "order";
    },
  },
  async mounted() {
    this.setRefundRequest();
    await this.setPaymentSourceChoices();

    await this.setTypeChoices();
    await this.setBankChoices();
    this.loading = false;
    if (this.bagDepositRefund) {
      this.form.type = "bag_deposit";
    }
  },
  methods: {
    getDefaultRefundRequest() {
      return {
        id: "",
        payment_source: "",
        cancellation_charge_percent: "",
        meta: this.getDefaultMeta(),
      };
    },
    getDefaultMeta() {
      return {
        bank: {
          name: "",
          account: {
            name: "",
            number: "",
          },
        },
      };
    },
    setRefundRequest() {
      if (this.initialData.id) {
        this.form = this.initialData;
      }
      if (!this.form.meta) {
        this.form.meta = this.getDefaultMeta();
      }
    },
    async saveRefundRequest() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        return;
      }
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      this.v$.$reset();
      let response;
      if (this.bagDepositRefund) {
        this.form.cancellation_charge_percent = 0;
        response = await apiOrderRefundRequests
          .createBagDepositRefund(this.clientId, this.form)
          .catch(handleError);
      } else if (this.form.type === "bag_deposit" && !this.bagDepositRefund) {
        this.form.cancellation_charge_percent = 0;
        response = await apiOrderRefundRequests
          .createBagRefundRequest(this.clientId, this.orderId, this.form)
          .catch(handleError);
      } else {
        response = await apiOrderRefundRequests
          .createOrderRefundRequest(this.clientId, this.orderId, this.form)
          .catch(handleError);
      }
      await handleResponse(response);
      if (response.status === 200) {
        this.$emit("saved");
        this.$emit("close");
      }
      this.formSubmitted = false;
    },
    async setPaymentSourceChoices() {
      this.paymentSourceChoices = [];
      const response = await apiMiscList
        .refundPaymentSources()
        .catch(handleError);
      this.paymentSourceChoices = formatDataToChoicesJs(
        await handleResponse(response),
        "",
        "enum"
      );
    },

    async setTypeChoices() {
      const response = await apiMiscList.refundTypes().catch(handleError);
      this.typeChoices = formatDataToChoicesJs(
        await handleResponse(response),
        "",
        "enum"
      );
    },

    async setBankChoices() {
      this.bankChoices = formatDataToChoicesJs(apiMiscList.banks());
    },
  },

  validations() {
    return {
      form: {
        type: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        payment_source: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        meta: {
          bank: {
            name: {
              required: helpers.withMessage(
                validatorMessages.required,
                requiredIf(() => this.requiresBankDetails)
              ),
            },
            account: {
              name: {
                required: helpers.withMessage(
                  validatorMessages.required,
                  requiredIf(() => this.requiresBankDetails)
                ),
              },
              number: {
                required: helpers.withMessage(
                  validatorMessages.required,
                  requiredIf(() => this.requiresBankDetails)
                ),
              },
            },
          },
        },

        cancellation_charge_percent: {
          required: helpers.withMessage(
            validatorMessages.required,
            requiredIf(() => this.requiresCancellationCharge)
          ),
          numeric: helpers.withMessage(validatorMessages.numeric, numeric),
        },
      },
    };
  },
};
</script>
