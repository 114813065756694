<template>
  <div ref="draggableRef" class="draggable" :style="style">
    <div class="top-bar">
      <div class="top-bar-title">{{ title }}</div>
      <div class="top-bar-icons">
        <div class="icon" @click="minimizeWindow">
          <i class="fas fa-window-minimize"></i>
        </div>
        <div class="icon" @click="maximizeWindow">
          <i class="fas fa-window-maximize"></i>
        </div>
      </div>
    </div>
    <div v-if="!minimizedWindow" class="content">
      <slot></slot>
    </div>
    <div
      v-if="!minimizedWindow"
      class="resize-handle"
      @mousedown="initResize"
      @touchstart="initResize"
    >
      <i class="fas fa-expand-alt fa-rotate-90"></i>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  nextTick,
  watch,
  onBeforeUnmount,
} from "vue";
import { useDraggable } from "@vueuse/core";
defineProps({
  title: {
    type: String,
    default: "Default Title",
  },
});
const draggableRef = ref(null);
const x = ref(0);
const y = ref(0);
const width = ref(600);
const height = ref(350);
const isResizing = ref(false);
const style = computed(() => ({
  transform: `translate(${x.value}px, ${y.value}px)`,
  position: "fixed",
  width: `${width.value}px`,
  height: `${height.value}px`,
  border: "1px solid #ccc",
  borderRadius: "5px",
  background: "white",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
  opacity: minimizedWindow.value ? 0.5 : 0.9,
}));
const minimizedWindow = ref(false);
const minimizeWindow = () => {
  width.value = 200;
  height.value = 40;
  minimizedWindow.value = true;
};

const maximizeWindow = () => {
  width.value = 600;
  height.value = 350;
  minimizedWindow.value = false;
};

onMounted(async () => {
  await nextTick();
  if (draggableRef.value) {
    const savedPosition = JSON.parse(
      localStorage.getItem("draggable-position")
    );

    const elementWidth = draggableRef.value.offsetWidth || 200;
    const elementHeight = draggableRef.value.offsetHeight || 100;

    const initialPosition = savedPosition || {
      x: window.innerWidth - elementWidth - 20,
      y: window.innerHeight - elementHeight - 20,
    };

    x.value = initialPosition.x;
    y.value = initialPosition.y;

    const { x: dragX, y: dragY } = useDraggable(draggableRef, {
      initialValue: { x: initialPosition.x, y: initialPosition.y },
      axis: "both",
    });

    watch([dragX, dragY], ([newX, newY], [oldX, oldY]) => {
      if (!isResizing.value && (newX !== oldX || newY !== oldY)) {
        x.value = newX;
        y.value = newY;
        localStorage.setItem(
          "draggable-position",
          JSON.stringify({ x: newX, y: newY })
        );
      }
    });
  }
});

const initResize = (event) => {
  isResizing.value = true;
  event.preventDefault();

  document.addEventListener("mousemove", resizeElement);
  document.addEventListener("mouseup", stopResize);
  document.addEventListener("touchmove", resizeElement);
  document.addEventListener("touchend", stopResize);
};

const resizeElement = (event) => {
  let clientX, clientY;

  if (typeof TouchEvent !== "undefined" && event instanceof TouchEvent) {
    clientX = event.touches[0].clientX;
    clientY = event.touches[0].clientY;
  } else {
    clientX = event.clientX;
    clientY = event.clientY;
  }

  if (draggableRef.value) {
    const boundingRect = draggableRef.value.getBoundingClientRect();
    const newWidth = clientX - boundingRect.left;
    const newHeight = clientY - boundingRect.top;

    width.value = Math.max(newWidth, 100);
    height.value = Math.max(newHeight, 100);
  }
};

const stopResize = () => {
  isResizing.value = false;
  document.removeEventListener("mousemove", resizeElement);
  document.removeEventListener("mouseup", stopResize);
  document.removeEventListener("touchmove", resizeElement);
  document.removeEventListener("touchend", stopResize);
};

onBeforeUnmount(() => {
  stopResize();
});
</script>

<style scoped>
.draggable {
  cursor: move;
  z-index: 10000;
  top: 0;
  left: 0;
  background: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  user-select: none;
  opacity: 0.9;
  position: relative;
}

.draggable:hover,
.draggable:focus {
  opacity: 1 !important;
}

.top-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: #e0e0e0;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 10001;
}

.top-bar-title {
  font-size: 14px;
  font-weight: bold;
}

.top-bar-icons {
  display: flex;
  align-items: center;
}

.icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.content {
  padding-top: 40px;
  height: 100%;
}

.resize-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: se-resize;
  background: white;
}
</style>
