<template>
  <div class="card mt-4 d-print-none">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-12 mt-3">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Client purchase type:</strong>
              &nbsp;
              {{ invoice.client_purchase_type }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Initiator type:</strong>
              &nbsp;
              {{ invoice.initiator_type }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Order duration type:</strong>
              &nbsp;
              {{ invoice.order_duration_type || "" }}
            </li>
            <li
              class="pt-0 text-sm border-0 list-group-item ps-0 d-flex align-items-center"
            >
              <template v-if="!showChangeUserForm">
                <strong class="text-dark">Invoice owner:</strong>
                &nbsp;
                {{ invoice.owner_name }}
                <div v-show="$can('update', 'invoice_users')" class="ms-3">
                  <argon-button
                    class="btn-xs"
                    color="success"
                    variant="gradient"
                    type="button"
                    name="button"
                    @click="showChangeUserForm = true"
                    >Change User
                  </argon-button>
                </div>
              </template>
              <div v-show="showChangeUserForm" class="row w-100">
                <div class="col-6">
                  <argon-select
                    id="changeUser"
                    class="mb-0"
                    :model-value="changedUserId"
                    :search-options-function="apiUsers.searchOptions"
                    :search-function="apiUsers.dropdown"
                    :label-fields="{
                      id: 'id',
                      value: 'id',
                      label: 'name',
                    }"
                    @update:model-value="handleUserChange"
                  />
                </div>
                <div class="col-6 d-flex align-items-center">
                  <submit-form-button
                    default-class="btn btn-success btn-xs mb-0"
                    form-submitted-class="btn btn-dark btn-xs mb-0"
                    :form-submitted="formSubmitted"
                    @click="handleUserChangeSubmit"
                  >
                    Save
                  </submit-form-button>
                  <argon-button
                    color="success"
                    variant="gradient"
                    type="button"
                    name="button"
                    class="ms-2 btn-xs"
                    @click="showChangeUserForm = false"
                    >Close
                  </argon-button>
                </div>
              </div>
            </li>

            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Invoice type:</strong>
              &nbsp;
              {{ invoice.type }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Invoice source:</strong>
              &nbsp;
              {{ invoice.source }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Invoice status:</strong>
              &nbsp;
              {{ invoice.status }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">UTM source:</strong>
              &nbsp;
              {{ invoice.utm_source }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">UTM campaign:</strong>
              &nbsp;
              {{ invoice.utm_campaign }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Payment source:</strong>
              &nbsp;
              {{ invoice.payment_source }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Payment status:</strong>
              &nbsp;
              {{ invoice.payment_status }}
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Payment date:</strong>
              &nbsp;
              {{
                invoice.payment_status === "success"
                  ? dateFormat(invoice.payment_time)
                  : invoice.payment_status
              }}
            </li>
            <li
              v-if="
                invoice.type != 'credit_note' && invoice.status == 'published'
              "
              class="pt-0 text-sm border-0 list-group-item ps-0"
            >
              <strong class="text-dark">Payment link:</strong>
              &nbsp;
              <popper-copy-text
                :text="invoice.payment_link"
                copied-text="Payment link copied"
                >{{ invoice.payment_link }}</popper-copy-text
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PopperCopyText from "@/components/PopperCopyText.vue";
import { dateFormat } from "@/lib/dateHelper";
import ArgonButton from "@/components/ArgonButton.vue";
import apiUsers from "@/services/apiUsers";
import ArgonSelect from "@/components/ArgonSelect.vue";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
import { handleError, handleResponse } from "@/lib/helpers";
import apiOrders from "@/services/apiOrders";

export default {
  name: "InvoiceDetailsCard",
  components: { PopperCopyText, ArgonButton, ArgonSelect, SubmitFormButton },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  emits: ["update"],
  data() {
    return {
      showChangeUserForm: false,
      changedUserId: "",
      formSubmitted: false,
    };
  },
  computed: {
    apiUsers() {
      return apiUsers;
    },
  },
  methods: {
    dateFormat,
    async handleUserChange(newUserId) {
      if (!newUserId || this.changedUserId == newUserId) {
        return;
      }
      this.changedUserId = newUserId;
    },
    async handleUserChangeSubmit() {
      if (!this.changedUserId) {
        return;
      }
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const response = await apiOrders
        .changeUser(this.invoice.client_id, this.invoice.id, {
          user_id: this.changedUserId,
        })
        .catch(handleError);
      await handleResponse(response);
      this.$emit("update");
      this.formSubmitted = false;
      this.showChangeUserForm = false;
    },
  },
};
</script>
<style scoped></style>
