<template>
  <div class="py-4 container-fluid">
    <div class="row d-print-none">
      <div class="text-right col-lg-12 d-flex flex-row justify-content-end">
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <button
            v-if="
              invoice.status == 'published' &&
              $can('create', 'invoice_payments')
            "
            class="btn btn-white text-success ms-3"
            @click="showModalObject(makePaidModal)"
          >
            Make Paid
          </button>
          <button
            v-if="invoice.status == 'published'"
            class="btn btn-white text-success ms-3"
            @click="showModalObject(manualPaymentModal)"
          >
            Manual Payment
          </button>
          <button
            v-if="invoice.status == 'published'"
            class="btn btn-white text-success ms-3"
            @click="showModalObject(walletPaymentModal)"
          >
            Wallet Payment
          </button>
          <submit-form-button
            v-if="invoice.status == 'published'"
            :form-submitted="formSubmitted"
            default-button-text="UnPublish Invoice"
            default-class="btn btn-white text-success ms-3 "
            form-submitted-class="btn btn-dark mb-0 ms-3"
            @click="
              unPublishInvoice(
                'unpublished',
                'Are you sure you want to unpublish the invoice?'
              )
            "
          />
          <submit-form-button
            v-if="invoice.status != 'cancelled' && invoice.status != 'paid'"
            :form-submitted="formSubmitted"
            default-button-text="Cancel Invoice"
            default-class="btn btn-white text-success ms-3 "
            form-submitted-class="btn btn-dark mb-0 ms-3"
            @click="
              unPublishInvoice(
                'cancelled',
                'Are you sure you want to cancel the invoice?'
              )
            "
          />
          <button
            class="btn btn-white text-success ms-3"
            @click="downloadInvoice()"
          >
            Download
          </button>
          <router-link
            v-if="invoice.client_id"
            :to="{
              name: 'Client Profile',
              params: { id: invoice.client_id },
            }"
            class="ms-3 btn btn-white text-success"
          >
            View Client
          </router-link>
          <router-link
            v-if="invoice.id && invoiceOrderId"
            :to="{
              name: 'Client Order',
              params: {
                id: invoice.client_id,
                orderId: invoiceOrderId,
              },
            }"
            class="ms-3 btn btn-white text-success"
          >
            View Order
          </router-link>
          <router-link
            :to="{ name: 'Invoices List' }"
            class="ms-3 btn btn-white text-success"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>

    <loading-spinner :loading="loading" :screen-center="true" />
    <div v-show="!loading" class="row">
      <div class="mt-4 col-lg-8 mx-auto">
        <invoice-summary-card
          v-if="!loading"
          :invoice="invoice"
          :can-add-promo-code="canAddPromoCode"
          @remove-promo-code="removePromoCode"
        />
        <div v-if="canAddPromoCode" class="card mt-4 d-print-none">
          <div class="card-body">
            <div class="row mt-3">
              <div v-show="!showPromoCodeForm" class="col-12">
                <argon-button
                  class="btn-sm"
                  color="success"
                  variant="gradient"
                  type="button"
                  name="button"
                  @click="showPromoCodeForm = true"
                  >Promo Code
                </argon-button>
              </div>
            </div>
            <div v-show="showPromoCodeForm" class="row mt-3">
              <div class="col-6">
                <select id="promo-code" v-model="promoCode"></select>
              </div>
              <div class="col-6">
                <argon-button
                  color="success"
                  variant="gradient"
                  type="button"
                  name="button"
                  class="btn-xs"
                  @click="applyPromoCode"
                  >Apply Promo Code
                </argon-button>
                <argon-button
                  color="success"
                  variant="gradient"
                  type="button"
                  name="button"
                  class="ms-2 btn-xs"
                  @click="showPromoCodeForm = false"
                  >Close
                </argon-button>
              </div>
            </div>
          </div>
        </div>
        <invoice-details-card
          v-if="!loading"
          :invoice="invoice"
          @update="setInvoice()"
        />
        <invoice-payments-card
          v-if="!loading && payments.length > 0"
          class="mt-4"
          :payments="payments"
        />
      </div>
    </div>
  </div>
  <make-paid-modal
    v-if="makePaidModal.show"
    :modal-id="makePaidModal.id"
    :client-id="invoice.client_id"
    :invoice-id="invoice.id"
    @save="handleMakePaidSaved"
    @close="closeModalObject(makePaidModal)"
  />
  <manual-payment-modal
    v-if="manualPaymentModal.show"
    :modal-id="manualPaymentModal.id"
    :client-id="invoice.client_id"
    :invoice-id="invoice.id"
    @save="handleManualPaymentSaved"
    @close="closeModalObject(manualPaymentModal)"
  />
  <wallet-payment-modal
    v-if="walletPaymentModal.show"
    :modal-id="walletPaymentModal.id"
    :client-id="invoice.client_id"
    :invoice-id="invoice.id"
    @save="handleWalletPaymentSaved"
    @close="closeModalObject(walletPaymentModal)"
  />
</template>

<script>
import API from "@/services/api";
import { showConfirmation, showMessage } from "@/assets/js/show-message";
import { handleError, handleResponse } from "@/lib/helpers";
import LoadingSpinner from "@/components/LoadingSpinner";
import ArgonButton from "@/components/ArgonButton.vue";
import {
  formatDataToChoicesJs,
  initChoicesAdvanced,
} from "@/assets/js/init-choices";
import apiPromoCodes from "@/services/apiPromoCodes";
import apiOrders from "@/services/apiOrders";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
import apiInvoices from "@/services/apiInvoices";
import ManualPaymentModal from "@/views/pages/Invoices/components/ManualPaymentModal.vue";
import { closeModalObject, showModalObject } from "@/lib/bootstrap";
import WalletPaymentModal from "@/views/pages/Invoices/components/WalletPaymentModal.vue";
import MakePaidModal from "@/views/pages/Invoices/components/MakePaidModal.vue";
import InvoiceSummaryCard from "@/views/pages/Invoices/components/InvoiceSummaryCard.vue";
import InvoiceDetailsCard from "@/views/pages/Invoices/components/InvoiceDetailsCard.vue";
import InvoicePaymentsCard from "@/views/pages/Invoices/components/InvoicePaymentsCard.vue";
export default {
  name: "ViewInvoice",
  components: {
    InvoicePaymentsCard,
    InvoiceDetailsCard,
    InvoiceSummaryCard,
    MakePaidModal,
    WalletPaymentModal,
    ManualPaymentModal,
    SubmitFormButton,
    ArgonButton,
    LoadingSpinner,
  },
  data() {
    return {
      makePaidModal: {
        show: false,
        id: "make-paid-modal",
      },
      manualPaymentModal: {
        show: false,
        id: "manual-payment-modal",
      },
      walletPaymentModal: {
        show: false,
        id: "wallet-payment-modal",
      },
      invoice: [],
      showPromoCodeForm: false,
      promoCode: null,
      loading: true,
      pdfUrl: "",
      formSubmitted: false,
      payments: [],
    };
  },
  computed: {
    invoiceOrderId() {
      const orderItem = this.invoice.invoiceItems.data.find(
        (item) => item.type === "order" && item.document_id !== null
      );
      return orderItem ? orderItem.document_id : null;
    },
    canAddPromoCode() {
      const invalidStatuses = ["cancelled", "paid", "trashed"];
      return !invalidStatuses.includes(this.invoice.status?.toLowerCase());
    },
  },
  async mounted() {
    await this.setInvoice();
    if (this.canAddPromoCode) {
      await this.initPromoCodes();
    }
    this.loading = false;
  },
  methods: {
    showModalObject,
    closeModalObject,
    async setPayments() {
      const response = await apiInvoices
        .payments(this.invoice.client_id, this.invoice.id, {
          start: 0,
          length: -1,
        })
        .catch(handleError);
      this.payments = await handleResponse(response);
    },
    async initPromoCodes(searchValue) {
      let choices = [];
      const options = apiPromoCodes.searchOptions(searchValue);
      const response = await apiPromoCodes.index(options).catch(handleError);
      const rows = await handleResponse(response);
      if (rows.length > 0) {
        choices = formatDataToChoicesJs(rows, [], {
          value: "code",
          label: "title",
          id: "id",
        });
      }
      await initChoicesAdvanced(
        "promo-code",
        { choices: choices },
        this.initPromoCodes
      );
    },
    async applyPromoCode() {
      if (!this.promoCode) {
        return;
      }
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const response = await apiOrders
        .applyPromoCode(this.invoice.client_id, this.invoiceOrderId, {
          code: this.promoCode,
        })
        .catch(handleError);
      await handleResponse(response);
      await this.setInvoice();
      this.formSubmitted = false;
    },
    async removePromoCode(promoCodeId) {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const response = await apiOrders
        .removePromoCode(
          this.invoice.client_id,
          this.invoiceOrderId,
          promoCodeId
        )
        .catch(handleError);
      await handleResponse(response);
      await this.setInvoice();
      this.formSubmitted = false;
    },
    async setInvoice() {
      this.loading = true;
      await API.getInvoice(this.$route.params.id)
        .then((res) => {
          this.invoice = res.data.data;
          this.invoiceItems = this.invoice.invoiceItems.data;
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            this.$swal({
              icon: "error",
              title: "Invoice not found",
              willClose: () => {
                this.$router.push({ name: "Invoice List" });
              },
            });
          } else {
            const response = API.handleError(err);
            showMessage(response.message, "error");
          }
        });
      await this.setPayments();
      this.loading = false;
    },

    async downloadInvoice() {
      try {
        const appInstance = this;
        const response = await API.getInvoicePdf(
          appInstance.$route.params.id,
          "download"
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "invoice.pdf");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        showMessage("Error downloading invoice:", "error");
      }
    },
    async unPublishInvoice(status, confirmationTitle) {
      if (this.formSubmitted) {
        return;
      }
      const response = await showConfirmation({
        title: confirmationTitle,
      });
      if (response) {
        this.formSubmitted = true;
        const response = await apiInvoices
          .unPublish(this.invoice.client_id, this.invoice.id, {
            status: status,
          })
          .catch(handleError);
        await handleResponse(response);
        await this.setInvoice();
        this.formSubmitted = false;
      }
    },
    handleManualPaymentSaved() {
      this.setInvoice();
      closeModalObject(this.manualPaymentModal);
    },
    handleWalletPaymentSaved() {
      this.setInvoice();
      closeModalObject(this.walletPaymentModal);
    },
    handleMakePaidSaved() {
      this.setInvoice();
      closeModalObject(this.manualPaymentModal);
    },
  },
};
</script>
<style>
@media print {
  body.bg-gray-100 {
    background: #ffffff !important;
  }

  .sidenav.fixed-start + .main-content {
    margin-left: 0 !important;
    background: #ffffff;
  }

  .invoice.container-fluid {
    margin: 0 !important;
    padding: 0 !important;
  }
}
</style>
