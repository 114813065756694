<template>
  <div class="row g-1" :class="{ 'opacity-5': !isVisible }">
    <div v-if="deliveryDate" class="col-12">
      <h6>Delivery Date: {{ dateFormat(deliveryDate) }}</h6>
    </div>
    <div
      v-for="(product, productIndex) in meal.products"
      :key="productIndex"
      class="mb-3"
    >
      <div
        v-if="product.meal_components && product.meal_components.length > 0"
        class="col"
      >
        <div class="d-flex align-items-start">
          <div class="img-wrap">
            <img
              v-for="(image, key) in getProductImages(product)"
              :key="key"
              alt=""
              :src="image.src"
              class="img-meal"
              @click="showProductImageModal(product)"
            />
          </div>
          <div class="ms-4">
            <div
              v-for="(component, index) in product.meal_components"
              :key="component.id"
              class="text-sm mb-1"
            >
              {{ component.product_name }} {{ component.weight
              }}{{ component.unit }}
              <dislikes-allergens-icons :item="component" />
              <span v-if="component.status === 'inactive'">
                <i
                  class="ms-2 fas fa-exclamation-triangle text-warning"
                  title="Inactive"
                ></i>
              </span>
              <strong class="ms-1"
                >{{ currencyFormat(component.price) }}
              </strong>

              <span v-if="index == 0 && isVisible && showActionButtons">
                <span class="ms-2 cursor-pointer" @click="editMeal(product)">
                  <i class="far fa-edit text-secondary"></i>
                </span>
                <span class="ms-2 cursor-pointer" @click="deleteMeal(product)">
                  <i class="fas fa-trash text-secondary"></i>
                </span>
              </span>

              <br />
              <span> Calories:{{ component.kcal }} </span>
              <span> Carbs:{{ component.carb }} </span>
              <span> Fat:{{ component.fat }} </span>
              <span> Protein:{{ component.protein }} </span>
            </div>
          </div>
        </div>
        <product-image-modal
          v-if="modals.productImage.show"
          :images="modals.productImage.images"
          :modal-id="modals.productImage.id"
          :title="modals.productImage.title"
          @close="closeModalObject(modals.productImage)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { currencyFormat } from "@/lib/helpers";
import { dateFormat } from "@/lib/dateHelper";
import { closeModalObject, showModalObject } from "@/lib/bootstrap";
import ProductImageModal from "@/components/ProductImageModal.vue";
import DislikesAllergensIcons from "@/components/DislikesAllergensIcons.vue";

export default {
  name: "OrderMealItem",
  components: { ProductImageModal, DislikesAllergensIcons },
  props: {
    deliveryDate: {
      type: String,
      default: "",
    },
    meal: {
      type: Object,
      required: true,
    },
    showActionButtons: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["deleteMeal", "editMeal"],
  data() {
    return {
      modals: {
        productImage: {
          show: false,
          id: "product-image-modal",
          images: [],
          title: "",
        },
      },
    };
  },
  computed: {
    isVisible() {
      return typeof this.meal.isVisible === "undefined" || this.meal.isVisible;
    },
  },
  methods: {
    closeModalObject,
    showModalObject,
    dateFormat,
    currencyFormat,
    getProductImages(product) {
      return product.meal_components
        .filter((component) => component.product_type !== "sauce")
        .map((component) => ({
          src: component.product_image,
          type: component.product_type,
        }));
    },

    getProductTitle(product) {
      return product.meal_components
        .map(
          (component) =>
            `${component.product_name} (${component.weight}${component.unit})`
        )
        .join(", ");
    },

    showProductImageModal(product) {
      this.modals.productImage.title = this.getProductTitle(product);
      this.modals.productImage.images = this.getProductImages(product);
      this.showModalObject(this.modals.productImage);
    },

    convertMenuItemToTargetStructure(product) {
      const convertedMealComponents = product.meal_components.map(
        (component) => ({
          id: component.id,
          product_id: product.product_id,
          product_variation_id: component.product_variation_id,
          price: component.price,
          quantity: 1,
          total_price: component.price,
          productVariation: {
            data: {
              id: component.product_variation_id,
              product_id: component.product_id,
              "product.name": component.product_name,
              "product.type": component.product_type,
              "product.sub_type": component.product_sub_type,
              "product.allergens": component.product_allergens || [],
              "product.app_image": component.product_image,
              weight: component.weight,
              unit: component.unit,
              kcal: component.kcal,
              fat: component.fat,
              carb: component.carb,
              pro: component.protein,
              price: component.price,
              active_from: null,
              inactive_from: null,
              product_status: component.product_status,
              status: component.status,
            },
          },
          client_allergens: component.client_allergens || [],
          client_protein_category_dislike:
            component.client_protein_category_dislike || [],
          client_veg_dislike: component.client_veg_dislike || [],
          client_carb_dislike: component.client_carb_dislike || [],
        })
      );

      return {
        id: product.order_day_pack_meal_id,
        order_day_pack_id: this.meal.order_day_pack_id,
        meal_type: this.meal.meal_type,
        quantity: 1,
        allow_delete: false,
        pvcs: this.meal.pvcs || "",
        dayPackMealComponents: {
          data: convertedMealComponents,
        },
        isVisible:
          this.meal.isVisible !== undefined ? this.meal.isVisible : true,
      };
    },

    editMeal(product) {
      const convertedProduct = this.convertMenuItemToTargetStructure(product);
      this.$emit("editMeal", {
        mealType: this.meal.meal_type,
        menuItem: convertedProduct,
        actionType: "Edit",
        mealId: this.meal.id,
        dayPackId: this.meal.order_day_pack_id,
        deliveryDate: this.deliveryDate,
      });
    },

    deleteMeal(product) {
      this.$emit("deleteMeal", product);
    },
  },
};
</script>

<style>
.img-meal {
  width: auto !important;
  padding: 0 !important;
  height: 50px;
}
.img-wrap {
  min-width: 70px;
}
</style>
