<template>
  <div class="container-fluid p-4 overflow-scroll h-100">
    <div class="subtitle mb-2">
      {{ mealTemplateDetails?.sub_title }}
    </div>
    <div class="d-inline-flex gap-2 border-bottom w-100 pb-2">
      <p class="mb-0">
        <strong>kcal:</strong> {{ mealTemplateDetails?.macros.kcal }}
      </p>
      <p class="mb-0">
        <strong>fat:</strong> {{ mealTemplateDetails?.macros.fat }}
      </p>
      <p class="mb-0">
        <strong>carbs:</strong> {{ mealTemplateDetails?.macros.carb }}
      </p>
      <p class="mb-0">
        <strong>protein:</strong> {{ mealTemplateDetails?.macros.pro }}
      </p>
      <p class="mb-0">
        <strong>Price:</strong> {{ mealTemplateDetails?.macros.price }}
      </p>
    </div>
    <div id="accordionExample" class="accordion">
      <div
        v-for="(weekData, weekKey) in mealTemplateSummary?.weeklySplit"
        :key="weekKey"
        class="accordion-item :last border-bottom py-2"
      >
        <div>{{ weekData.title }}</div>
        <div class="d-inline-flex gap-2">
          <p class="mb-0"><strong>kcal:</strong> {{ weekData.macros.kcal }}</p>
          <p class="mb-0"><strong>fat:</strong> {{ weekData.macros.fat }}</p>
          <p class="mb-0"><strong>carbs:</strong> {{ weekData.macros.carb }}</p>
          <p class="mb-0">
            <strong>protein:</strong> {{ weekData.macros.pro }}
          </p>
          <p class="mb-0">
            <strong>Price:</strong> {{ weekData.macros.price }}
          </p>
        </div>
        <div :id="`heading-${weekKey}`" class="accordion-header accordion-head">
          <button
            class="accordion-button collapsed p-0"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#collapse-${weekKey}`"
            aria-expanded="false"
            :aria-controls="`collapse-${weekKey}`"
          >
            <div class="button-content">
              <span> {{ weekData.daySplit.title }}</span>
              <Arrow class="arrow" :size="16" />
            </div>
          </button>
        </div>
        <div
          :id="`collapse-${weekKey}`"
          class="accordion-collapse collapse accordion-body"
          :aria-labelledby="`heading-${weekKey}`"
          data-bs-parent="#accordionExample"
        >
          <div
            v-for="(dayData, dayKey) in weekData.daySplit.days"
            :key="dayKey"
            class="accordion-item border-bottom pt-2"
          >
            <div class="font-weight-bold">
              {{ dayData.title }}
            </div>
            <div class="d-inline-flex gap-2">
              <p class="mb-0">
                <strong>kcal:</strong> {{ dayData.macros.kcal }}
              </p>
              <p class="mb-0"><strong>fat:</strong> {{ dayData.macros.fat }}</p>
              <p class="mb-0">
                <strong>carbs:</strong> {{ dayData.macros.carb }}
              </p>
              <p class="mb-0">
                <strong>protein:</strong> {{ dayData.macros.pro }}
              </p>
              <p class="mb-0">
                <strong>Price:</strong> {{ dayData.macros.price }}
              </p>
            </div>
            <div
              v-for="(mealList, mealType) in dayData.meals"
              :key="mealType"
              class="my-2"
            >
              <div v-for="(meal, mealIndex) in mealList" :key="mealIndex">
                <p class="mb-0">
                  <span class="font-weight-bold">{{ mealType }}:</span>
                  {{ meal.meal.join(", ") }}
                </p>
                <div class="d-inline-flex gap-2">
                  <p class="mb-0">
                    <strong>kcal:</strong> {{ meal.macros.kcal }}
                  </p>
                  <p class="mb-0">
                    <strong>fat:</strong> {{ meal.macros.fat }}
                  </p>
                  <p class="mb-0">
                    <strong>carbs:</strong> {{ meal.macros.carb }}
                  </p>
                  <p class="mb-0">
                    <strong>protein:</strong> {{ meal.macros.pro }}
                  </p>
                  <p class="mb-0">
                    <strong>Price:</strong> {{ meal.macros.price }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from "@/components/Icons/Arrow.vue";

export default {
  name: "MealItemSummary",
  components: {
    Arrow,
  },
  props: {
    mealTemplateSummary: {
      type: Object,
      required: true,
    },
  },
  computed: {
    mealTemplateDetails() {
      return this.mealTemplateSummary.mealTemplateSummary;
    },
    weeklySplit() {
      return this.mealTemplateSummary.weeklySplit;
    },
  },
};
</script>

<style scoped lang="scss">
.subtitle {
  font-weight: 500;
  font-size: 18px;
}
.accordion-body {
  border: 1px solid #e4e8ed;
  border-top: 0;
  margin-top: -10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.accordion-head {
  background-color: #e4e8ed;
  padding: 10px;
  margin-top: 4px;
  border-radius: 10px;
  .accordion-button {
    color: #5974a2;
    font-weight: 600;
    .button-content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      .arrow {
        transform: rotate(180deg);
        transition: ease-in 0.2s all;
      }
    }
    &.collapsed {
      .button-content {
        .arrow {
          transform: rotate(0deg);
          transition: ease-in 0.2s all;
        }
      }
    }
  }
}
</style>
