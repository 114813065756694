<template>
  <div class="card h-100 mt-4">
    <div class="card-body">
      <div v-if="$can('create', 'remarks')" class="mt-4 row">
        <div class="col-auto">
          <img
            alt="Image placeholder"
            class="avatar rounded-circle me-3"
            :src="logo"
          />
        </div>
        <div class="col col-md-6">
          <form @submit.prevent="createRemark">
            <div class="row">
              <div class="col-12">
                <input
                  v-model="formData.subject"
                  type="text"
                  class="form-control"
                  placeholder="Subject"
                />
              </div>
              <div class="col-12 mt-3">
                <select
                  id="remark-type"
                  v-model="formData.type"
                  placeholder="Select type"
                >
                  <option value="" disabled selected>Select type</option>
                </select>
              </div>
              <div class="col-12 mt-3">
                <textarea
                  v-model="formData.content"
                  class="form-control"
                  placeholder="Write a comment"
                  rows="4"
                ></textarea>
              </div>
              <div class="col-12 mt-3">
                <submit-form-button
                  default-class="btn btn-success btn-sm mb-0"
                  form-submitted-class="btn btn-dark btn-sm mb-0"
                  :form-submitted="formSubmitted"
                  @click="createRemark"
                >
                  Save
                </submit-form-button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div v-if="$can('view', 'remarks')" class="row">
        <div class="col-12 col-sm-6">
          <timeline-list v-if="clientRemarksMainSet.length > 0" class="mt-5">
            <timeline-item
              v-for="(el, index) in clientRemarksMainSet"
              :key="index"
              :icon="{
                component: 'ni ni-check-bold',
                color: 'success',
              }"
              :title="el.subject"
              :date-time="dateFormat(el.created_at, 'dateTime')"
              :description="el.content"
              :badges="[
                { title: el['author.name'], color: 'success' },
                { title: el.type, color: 'warning' },
              ]"
              :show-delete-button="$can('delete', 'remarks')"
              @delete="deleteRemark(el.id)"
            />
          </timeline-list>
        </div>
        <div class="col-12 col-sm-6">
          <timeline-list
            v-if="clientRemarksNutritionSet.length > 0"
            class="mt-5"
          >
            <timeline-item
              v-for="(el, index) in clientRemarksNutritionSet"
              :key="index"
              :icon="{
                component: 'ni ni-check-bold',
                color: 'success',
              }"
              :title="el.subject"
              :date-time="dateFormat(el.created_at, 'dateTime')"
              :description="el.content"
              :badges="[
                { title: el['author.name'], color: 'success' },
                { title: el.type, color: 'warning' },
              ]"
              :show-delete-button="$can('delete', 'remarks')"
              @delete="deleteRemark(el.id)"
            />
          </timeline-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { showConfirmation, showMessage } from "@/assets/js/show-message";
import { dateFormat } from "@/lib/dateHelper";
import { handleError, handleResponse } from "@/lib/helpers";
import logo from "@/assets/img/logo.svg";
import { initChoices } from "@/assets/js/init-choices";
import TimelineItem from "@/examples/Cards/TimelineItem.vue";
import TimelineList from "@/examples/Cards/TimelineList.vue";
import apiClientRemarks from "@/services/apiClientRemarks";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
export default {
  name: "RemarksCard",
  components: {
    TimelineItem,
    TimelineList,
    SubmitFormButton,
  },
  props: {
    remarkTypes: {
      type: Array,
      required: true,
    },
    clientRemarks: {
      type: Array,
      default: () => [],
    },
    clientId: {
      type: [String, Number],
      required: true,
    },
  },
  emits: ["created", "deleted"],
  data() {
    return {
      logo,
      formData: {
        subject: "",
        content: "",
        type: "",
        action_time: "",
      },
      formSubmitted: false,
    };
  },
  computed: {
    clientRemarksMainSet() {
      return this.clientRemarks.filter((item) => item.type !== "Nutrition");
    },
    clientRemarksNutritionSet() {
      return this.clientRemarks.filter((item) => item.type === "Nutrition");
    },
  },
  mounted() {
    initChoices(
      "remark-type",
      {
        choices: this.remarkTypes,
        searchEnabled: false,
      },
      ""
    );
  },
  methods: {
    dateFormat,
    async deleteRemark(remarkId) {
      if (this.formSubmitted) {
        showMessage("Please wait...", "error");
        return;
      }
      await showConfirmation({
        title: "Delete Remark",
        message: "Are you sure you want to delete this remark?",
        showCancelButton: true,
        showCloseButton: true,
        confirmAction: async () => {
          this.formSubmitted = true;
          const response = await apiClientRemarks
            .delete(this.clientId, remarkId)
            .catch(handleError);
          await handleResponse(response, () => {
            showMessage("Remark deleted succesfully", "success");
            this.$emit("deleted");
          });
          this.formSubmitted = false;
        },
      });
    },
    async createRemark() {
      if (this.formSubmitted) {
        showMessage("Please wait...", "error");
        return;
      }
      if (!this.formData.subject) {
        showMessage("Please write a subject", "error");
        return;
      }
      if (!this.formData.content) {
        showMessage("Please write a comment", "error");
        return;
      }

      if (!this.formData.type) {
        showMessage("Please select a type", "error");
        return;
      }
      this.formSubmitted = true;
      let data = {
        subject: this.formData.subject,
        content: this.formData.content,
        type: this.formData.type,
      };
      if (this.formData.action_time) {
        data.action_time = this.formData.action_time;
      }
      const response = await apiClientRemarks
        .store(this.clientId, data)
        .catch(handleError);
      if (response.status == 200) {
        this.formData = { subject: "", content: "", type: "", action_time: "" };
        showMessage("Remark created succesfully", "success");
        this.$emit("created");
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
  },
};
</script>
