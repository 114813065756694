import { createRouter, createWebHistory } from "vue-router";
import ability from "@/services/ability";
import store from "@/store";
import SignIn from "../views/auth/signin/Basic.vue";
import SignUp from "../views/auth/signup/Basic.vue";
import UsersList from "../views/pages/Users/List.vue";
import EditUser from "../views/pages/Users/EditUser.vue";
import NewUser from "../views/pages/Users/NewUser.vue";
import UserRoles from "../views/pages/Users/UserRoles.vue";
import EditRole from "../views/pages/Users/EditRole.vue";
import ClientsList from "../views/pages/Clients/List.vue";
import ClientsPlansList from "../views/pages/Clients/PlansList.vue";
import EditClient from "../views/pages/Clients/Edit.vue";
import CreateClient from "../views/pages/Clients/Create.vue";
import EditClientAddress from "../views/pages/Clients/EditAddress.vue";
import NewClientAddress from "../views/pages/Clients/NewAddress.vue";
import ProfileClient from "../views/pages/Clients/Profile.vue";
import ClientPlan from "../views/pages/Clients/Plan.vue";
import ClientOrder from "../views/pages/Clients/Order.vue";
import AllergensList from "../views/pages/Config/Allergens/List.vue";
import IngredientsList from "../views/pages/Config/Ingredients/List.vue";
import EditIngredient from "../views/pages/Config/Ingredients/Edit.vue";
import NewIngredient from "../views/pages/Config/Ingredients/New.vue";
import RecipesList from "../views/pages/Config/Recipes/List.vue";
import EditRecipe from "../views/pages/Config/Recipes/Edit.vue";
import NewRecipe from "../views/pages/Config/Recipes/New.vue";
import PlansList from "../views/pages/Config/Plans/List.vue";
import EditPlan from "../views/pages/Config/Plans/Edit.vue";
import NewPlan from "../views/pages/Config/Plans/New.vue";
import EditPlanVariant from "../views/pages/Config/PlanVariants/Edit.vue";
import NewPlanVariant from "../views/pages/Config/PlanVariants/New.vue";
import InvoicesList from "../views/pages/Invoices/List.vue";
import InvoiceDetails from "../views/pages/Invoices/ShowInvoice.vue";
import ProductsList from "../views/pages/Config/Products/List.vue";
import EditProduct from "../views/pages/Config/Products/Edit.vue";
import NewProduct from "../views/pages/Config/Products/New.vue";
import EditProductVariation from "../views/pages/Config/ProductVariations/Edit.vue";
import NewProductVariation from "../views/pages/Config/ProductVariations/New.vue";
import Error403 from "../views/auth/error/Error403.vue";
import Error404 from "../views/auth/error/Error404.vue";
import Error500 from "../views/auth/error/Error500.vue";
import FoodGroupsList from "../views/pages/Config/FoodGroups/List.vue";
import NewFoodGroup from "../views/pages/Config/FoodGroups/New.vue";
import EditFoodGroup from "../views/pages/Config/FoodGroups/Edit.vue";
import BagMovementsList from "../views/pages/Logistics/BagMovements/List.vue";
import BagDepositsList from "../views/pages/Clients/BagDeposits/List.vue";
import ChangeBagStatus from "../views/pages/Logistics/BagMovements/ChangeBagStatus.vue";
import ViewBagMovement from "../views/pages/Logistics/BagMovements/Details.vue";
import BagMovementCheckin from "../views/pages/Logistics/BagMovements/BagCheckin.vue";
import BagMovementCheckout from "../views/pages/Logistics/BagMovements/BagCheckout.vue";
import AddonsList from "../views/pages/Config/Addons/List.vue";
import NewAddons from "../views/pages/Config/Addons/New.vue";
import EditAddons from "../views/pages/Config/Addons/Edit.vue";
import ViewAddons from "../views/pages/Config/Addons/View.vue";
import PromoCodeList from "../views/pages/Config/PromoCodes/List.vue";
import ViewPromoCode from "../views/pages/Config/PromoCodes/View.vue";
import ConsultationsView from "../views/pages/Clients/Consultations/View.vue";
import NewConsultations from "../views/pages/Clients/Consultations/New.vue";
import EditConsultations from "../views/pages/Clients/Consultations/Edit.vue";
import MealTemplatesList from "../views/pages/Config/MealTemplates/List.vue";
import EditMealTemplate from "../views/pages/Config/MealTemplates/Edit.vue";
import NewMealTemplate from "../views/pages/Config/MealTemplates/New.vue";
import CustomMealTemplate from "../views/pages/Config/MealTemplates/Custom.vue";
const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/pages/Dashboards/DefaultDashboard.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/dashboard-super-admin",
    name: "Super Admin Dashboard",
    component: () => import("@/views/pages/Dashboards/SuperAdminDashboard.vue"),
    meta: {
      auth: true,
      title: "Super Admin Dashboard",
    },
  },
  {
    path: "/dashboard-account-manager",
    name: "Account Manager Dashboard",
    component: () =>
      import("@/views/pages/Dashboards/AccountManagerDashboard.vue"),
    meta: {
      auth: true,
      title: "Account Manager Dashboard",
    },
  },
  {
    path: "/dashboard-account-supervisor",
    name: "Account Supervisor Dashboard",
    component: () =>
      import("@/views/pages/Dashboards/AccountSupervisorDashboard.vue"),
    meta: {
      auth: true,
      title: "Account Supervisor Dashboard",
    },
  },
  {
    path: "/dashboard-sales-team-leader",
    name: "Sales Team Leader Dashboard",
    component: () =>
      import("@/views/pages/Dashboards/SalesTeamLeaderDashboard.vue"),
    meta: {
      auth: true,
      title: "Sales Team Leader Dashboard",
    },
  },
  {
    path: "/dashboard-sales-agent",
    name: "Sales Agent Dashboard",
    component: () => import("@/views/pages/Dashboards/SalesAgentDashboard.vue"),
    meta: {
      auth: true,
      title: "Sales Agent Dashboard",
    },
  },
  {
    path: "/dashboard-nutritionist",
    name: "Nutritionist Dashboard",
    component: () => import("@/views/pages/Dashboards/DefaultDashboard.vue"),
    meta: {
      auth: true,
      title: "Nutritionist Dashboard",
    },
  },
  {
    path: "/signin",
    name: "SignIn",
    component: SignIn,
    meta: {
      auth: true,
    },
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
    meta: {
      auth: false,
    },
  },
  {
    path: "/users",
    name: "Users List",
    component: UsersList,
    meta: {
      title: "Users List",
      auth: true,
      guard: {
        action: "view",
        subject: "users",
      },
    },
  },
  {
    path: "/users/:id",
    name: "Edit User",
    component: EditUser,
    meta: {
      title: "Edit User",
      auth: true,
      guard: {
        action: "update",
        subject: "users",
      },
    },
  },
  {
    path: "/users/new-user",
    name: "New User",
    component: NewUser,
    meta: {
      auth: true,
      guard: {
        action: "create",
        subject: "users",
      },
    },
  },
  {
    path: "/authentication/error/error404",
    name: "Error404",
    component: Error404,
    meta: {
      auth: false,
    },
  },
  {
    path: "/authentication/error/error403",
    name: "Error403",
    component: Error403,
    meta: {
      auth: false,
    },
  },
  {
    path: "/authentication/error/error500",
    name: "Error500",
    component: Error500,
    meta: {
      auth: false,
    },
  },
  {
    path: "/users/user-roles",
    name: "User Roles",
    component: UserRoles,
    meta: {
      title: "User Roles",
      auth: true,
      guard: {
        action: "view",
        subject: "roles",
      },
    },
  },
  {
    path: "/users/user-roles/:id",
    name: "Edit User Role",
    component: EditRole,
    meta: {
      title: "Edit Role",
      auth: true,
      guard: {
        action: "update",
        subject: "roles",
      },
    },
  },
  {
    path: "/clients",
    name: "Clients List",
    component: ClientsList,
    meta: {
      title: "Clients List",
      auth: true,
      guard: {
        action: "view",
        subject: "clients",
      },
    },
  },
  {
    path: "/clients/plans",
    name: "Clients Plans List",
    component: ClientsPlansList,
    meta: {
      title: "Clients Plans List",
      auth: true,
      guard: {
        action: "view",
        subject: "client_plan_details",
      },
    },
  },
  {
    path: "/clients/:id",
    name: "Edit Client",
    component: EditClient,
    meta: {
      title: "Edit Client",
      auth: true,
      guard: {
        action: "update",
        subject: "clients",
      },
    },
  },
  {
    path: "/clients/create/:mobileNumber",
    name: "Create Client",
    component: CreateClient,
    meta: {
      title: "Create Client",
      auth: true,
      guard: {
        action: "create",
        subject: "clients",
      },
    },
  },
  {
    path: "/clients/:id/addresses/:addressId",
    name: "Edit Client Address",
    component: EditClientAddress,
    meta: {
      title: "Edit Client Address",
      auth: true,
      guard: {
        action: "update",
        subject: "addresses",
      },
    },
  },
  {
    path: "/clients/:id/addresses",
    name: "New Client Address",
    component: NewClientAddress,
    meta: {
      title: "New Client Address",
      auth: true,
      guard: {
        action: "create",
        subject: "addresses",
      },
    },
  },
  {
    path: "/clients/:id/profile",
    name: "Client Profile",
    component: ProfileClient,
    meta: {
      title: "Client Profile",
      auth: true,
      guard: {
        action: "view",
        subject: "clients",
      },
    },
  },
  {
    path: "/clients/:id/plan/:planId",
    name: "Client Plan",
    component: ClientPlan,
    meta: {
      title: "Client Plan",
      auth: true,
      guard: {
        action: "view",
        subject: "clients",
      },
    },
  },
  {
    path: "/orders",
    name: "Order List",
    component: () => import("@/views/pages/Orders/List.vue"),
    meta: {
      title: "Order List",
      auth: true,
      guard: {
        action: "view",
        subject: "orders",
      },
    },
  },
  {
    path: "/clients/:client_id?/new-order/:meal_template_id?",
    name: "Create Order",
    component: () => import("@/views/pages/Orders/NewOrder.vue"),
    meta: {
      title: "Create Order",
      auth: true,
      guard: {
        action: "create",
        subject: "orders",
      },
    },
  },
  {
    path: "/clients/:client_id/new-custom-order",
    name: "Create Custom Order",
    component: () => import("@/views/pages/Orders/CustomNewOrder.vue"),
    meta: {
      title: "Create Custom Order",
      auth: true,
      guard: {
        action: "create",
        subject: "orders",
      },
    },
  },
  {
    path: "/clients/:id/orders/:orderId",
    name: "Client Order",
    component: ClientOrder,
    meta: {
      title: "Client Order",
      auth: true,
      guard: {
        action: "view",
        subject: "clients",
      },
    },
  },
  {
    path: "/config/allergens/",
    name: "Allergens",
    component: AllergensList,
    meta: {
      title: "Allergens",
      auth: true,
      guard: {
        action: "view",
        subject: "allergens",
      },
    },
  },
  {
    path: "/config/ingredients/",
    name: "Ingredients",
    component: IngredientsList,
    meta: {
      title: "Ingredients",
      auth: true,
      guard: {
        action: "view",
        subject: "ingredients",
      },
    },
  },
  {
    path: "/config/ingredients/:id",
    name: "Edit Ingredient",
    component: EditIngredient,
    meta: {
      title: "Edit Ingredient",
      auth: true,
      guard: {
        action: "update",
        subject: "ingredients",
      },
    },
  },
  {
    path: "/config/ingredients/new",
    name: "New Ingredient",
    component: NewIngredient,
    meta: {
      title: "New Ingredient",
      auth: true,
      guard: {
        action: "create",
        subject: "ingredients",
      },
    },
  },
  {
    path: "/config/recipes/",
    name: "Recipes",
    component: RecipesList,
    meta: {
      title: "Recipes",
      auth: true,
      guard: {
        action: "view",
        subject: "recipes",
      },
    },
  },
  {
    path: "/config/recipes/:id",
    name: "Edit Recipe",
    component: EditRecipe,
    meta: {
      title: "Edit Recipe",
      auth: true,
      guard: {
        action: "update",
        subject: "recipes",
      },
    },
  },
  {
    path: "/config/recipes/new",
    name: "New Recipe",
    component: NewRecipe,
    meta: {
      title: "New Recipe",
      auth: true,
      guard: {
        action: "create",
        subject: "recipes",
      },
    },
  },
  {
    path: "/config/plans/",
    name: "Plans",
    component: PlansList,
    meta: {
      title: "Plans",
      auth: true,
      guard: {
        action: "view",
        subject: "plans",
      },
    },
  },
  {
    path: "/config/plans/:id",
    name: "Edit Plan",
    component: EditPlan,
    meta: {
      title: "Edit Plan",
      auth: true,
      guard: {
        action: "update",
        subject: "plans",
      },
    },
  },
  {
    path: "/config/plans/new",
    name: "New Plan",
    component: NewPlan,
    meta: {
      title: "New Plan",
      auth: true,
      guard: {
        action: "create",
        subject: "plans",
      },
    },
  },
  {
    path: "/config/plans/:id/variants/:planVariantId",
    name: "Edit Plan Variant",
    component: EditPlanVariant,
    meta: {
      title: "Edit Plan Variant",
      auth: true,
      guard: {
        action: "update",
        subject: "plans",
      },
    },
  },
  {
    path: "/config/plans/:id/variants/new",
    name: "New Plan Variant",
    component: NewPlanVariant,
    meta: {
      title: "New Plan Variant",
      auth: true,
      guard: {
        action: "update",
        subject: "plans",
      },
    },
  },
  {
    path: "/invoices/",
    name: "Invoices List",
    component: InvoicesList,
    meta: {
      title: "Invoices List",
      auth: true,
      guard: {
        action: "view",
        subject: "invoices",
      },
    },
  },
  {
    path: "/invoices/:id",
    name: "Invoice",
    component: InvoiceDetails,
    meta: {
      title: "Invoice Details",
      auth: true,
      guard: {
        action: "view",
        subject: "invoices",
      },
    },
  },

  {
    path: "/config/products/",
    name: "Products",
    component: ProductsList,
    meta: {
      title: "Products",
      auth: true,
      guard: {
        action: "view",
        subject: "products",
      },
    },
  },
  {
    path: "/config/products/:id",
    name: "Edit Product",
    component: EditProduct,
    meta: {
      title: "Edit Product",
      auth: true,
      guard: {
        action: "update",
        subject: "products",
      },
    },
  },
  {
    path: "/config/products/view/:id",
    name: "View Product",
    component: EditProduct,
    meta: {
      title: "View Product",
      auth: true,
      guard: {
        action: "view",
        subject: "products",
      },
    },
  },
  {
    path: "/config/products/new",
    name: "New Product",
    component: NewProduct,
    meta: {
      title: "New product",
      auth: true,
      guard: {
        action: "create",
        subject: "products",
      },
    },
  },

  {
    path: "/config/products/:id/variation/:productVariationId",
    name: "Edit Product Variation",
    component: EditProductVariation,
    meta: {
      title: "Edit Product Variation",
      auth: true,
      guard: {
        action: "update",
        subject: "products",
      },
    },
  },
  {
    path: "/config/products/:id/variation/new",
    name: "New Product Variation",
    component: NewProductVariation,
    meta: {
      title: "New Product Variation",
      auth: true,
      guard: {
        action: "create",
        subject: "products",
      },
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "Page not found",
    component: Error404,
    meta: {
      auth: false,
    },
  },

  {
    path: "/config/food-groups/",
    name: "Food Groups",
    component: FoodGroupsList,
    meta: {
      title: "Food Groups",
      auth: true,
      guard: {
        action: "view",
        subject: "food_groups",
      },
    },
  },

  {
    path: "/config/food-groups/new",
    name: "New Food Group",
    component: NewFoodGroup,
    meta: {
      title: "New Food Group",
      auth: true,
      guard: {
        action: "create",
        subject: "food_groups",
      },
    },
  },

  {
    path: "/config/food-groups/:id",
    name: "Edit Food Group",
    component: EditFoodGroup,
    meta: {
      title: "Edit Food Group",
      auth: true,
      guard: {
        action: "update",
        subject: "food_groups",
      },
    },
  },

  {
    path: "/logistics/bag-movements/",
    name: "Bag Movements",
    component: BagMovementsList,
    meta: {
      title: "Bag Movements",
      auth: true,
      guard: {
        action: "view",
        subject: "bag_movements",
      },
    },
  },
  {
    path: "/clients/bag-deposits/",
    name: "Bag Deposits",
    component: BagDepositsList,
    meta: {
      title: "Bag Deposits",
      auth: true,
      guard: {
        action: "view",
        subject: "bag_deposits",
      },
    },
  },
  {
    path: "/logistics/bag-movements/change-bag-status",
    name: "Change Bag Status",
    component: ChangeBagStatus,
    meta: {
      title: "Change Bag Status",
      auth: true,
      guard: {
        action: "view",
        subject: "bag_movements",
      },
    },
  },
  {
    path: "/config/bag-movements/:id",
    name: "View Bag Movement",
    component: ViewBagMovement,
    meta: {
      title: "View Bag Movement",
      auth: true,
      guard: {
        action: "view",
        subject: "bag_movements",
      },
    },
  },
  {
    path: "/logistics/bag-movements/checkin",
    name: "Bag Movement Check-in",
    component: BagMovementCheckin,
    meta: {
      title: "Bag Movement Check-in",
      auth: true,
      guard: {
        action: "view",
        subject: "bag_movements",
      },
    },
  },
  {
    path: "/logistics/bag-movements/checkout",
    name: "Bag Movement Check-out",
    component: BagMovementCheckout,
    meta: {
      title: "Bag Movement Check-out",
      auth: true,
      guard: {
        action: "view",
        subject: "bag_movements",
      },
    },
  },
  {
    path: "/client/:id/bag-movements",
    name: "Client Bag Movements",
    component: BagMovementsList,
    meta: {
      title: "Client Bag Movements",
      auth: true,
      guard: {
        action: "view",
        subject: "bag_movements",
      },
    },
  },

  {
    path: "/config/addons/",
    name: "Addons",
    component: AddonsList,
    meta: {
      title: "Addons",
      auth: true,
      guard: {
        action: "view",
        subject: "addons",
      },
    },
  },

  {
    path: "/config/addons/new",
    name: "New Addons",
    component: NewAddons,
    meta: {
      title: "New Addons",
      auth: true,
      guard: {
        action: "create",
        subject: "addons",
      },
    },
  },

  {
    path: "/config/addons/:id",
    name: "Edit Addons",
    component: EditAddons,
    meta: {
      title: "Edit Addons",
      auth: true,
      guard: {
        action: "update",
        subject: "addons",
      },
    },
  },

  {
    path: "/config/addons/:id/show",
    name: "View Addons",
    component: ViewAddons,
    meta: {
      title: "View Addons",
      auth: false,
      guard: {
        action: "view",
        subject: "addons",
      },
    },
  },

  {
    path: "/config/promo-codes/",
    name: "Promo Codes",
    component: PromoCodeList,
    meta: {
      title: "Promo Code",
      auth: true,
      guard: {
        action: "view",
        subject: "discount_codes",
      },
    },
  },
  {
    path: "/config/promo-codes/new",
    name: "New Promo Code",
    component: () => import("@/views/pages/Config/PromoCodes/New.vue"),
    meta: {
      title: "New Promo Code",
      auth: true,
      guard: {
        action: "create",
        subject: "discount_codes",
      },
    },
  },

  {
    path: "/config/promo-codes/:id/view",
    name: "View Promo Code",
    component: ViewPromoCode,
    meta: {
      title: "View Promo Code",
      auth: true,
      guard: {
        action: "view",
        subject: "discount_codes",
      },
    },
  },

  {
    path: "/config/promo-codes/:id",
    name: "Edit Promo Code",
    component: () => import("@/views/pages/Config/PromoCodes/New.vue"),
    meta: {
      title: "Edit Promo Code",
      auth: true,
      guard: {
        action: "update",
        subject: "discount_codes",
      },
    },
  },
  {
    path: "/clients/:id/consultations/new",
    name: "New Consultation",
    component: NewConsultations,
    meta: {
      title: "New Consultation",
      auth: true,
      guard: {
        action: "view",
        subject: "clients",
      },
    },
  },
  {
    path: "/clients/:id/consultations/:consultationId",
    name: "Consultations View",
    component: ConsultationsView,
    meta: {
      title: "Consultations View",
      auth: true,
      guard: {
        action: "view",
        subject: "clients",
      },
    },
  },
  {
    path: "/clients/:id/consultations/Edit/:consultationId",
    name: "Edit Consultations",
    component: EditConsultations,
    meta: {
      title: "Edit Consultation",
      auth: true,
      guard: {
        action: "view",
        subject: "clients",
      },
    },
  },
  {
    path: "/config/meal-templates/",
    name: "Meal Templates",
    component: MealTemplatesList,
    meta: {
      title: "Meal Templates",
      auth: true,
      guard: {
        action: "view",
        subject: "meal_templates",
      },
    },
  },
  {
    path: "/config/meal-templates/:id",
    name: "Edit Meal Template",
    component: EditMealTemplate,
    meta: {
      title: "Edit Meal Template",
      auth: true,
      guard: {
        action: "update",
        subject: "meal_templates",
      },
    },
  },
  {
    path: "/config/meal-templates/new",
    name: "New Meal Template",
    component: NewMealTemplate,
    meta: {
      title: "New Meal Template",
      auth: true,
      guard: {
        action: "create",
        subject: "meal_templates",
      },
    },
  },
  {
    path: "/config/meal-templates/custom",
    name: "Custom Meal Template",
    component: CustomMealTemplate,
    meta: {
      title: "Custom Meal Template",
      auth: true,
      guard: {
        action: "create",
        subject: "meal_templates",
      },
    },
  },
  {
    path: "/config/meal-templates/automatic",
    name: "Automatic Meal Template",
    component: () => import("@/views/pages/Config/MealTemplates/Automatic.vue"),
    meta: {
      title: "Automatic Meal Template",
      auth: true,
      guard: {
        action: "create",
        subject: "meal_templates",
      },
    },
  },
  {
    path: "/nutritionist-queue",
    name: "Nutritionist Queue",
    component: () => import("@/views/pages/NutritionistQueue/List.vue"),
    meta: {
      title: "Nutritionist Queue",
      auth: true,
      guard: {
        action: "view",
        subject: "nutrition_queues",
      },
    },
  },
  {
    path: "/nutritionist-queue/add",
    name: "New Nutritionist Queue Request",
    component: () => import("@/views/pages/NutritionistQueue/Details.vue"),
    meta: {
      title: "New Nutritionist Queue Request",
      auth: true,
      guard: {
        action: "create",
        subject: "nutrition_queues",
      },
    },
  },
  {
    path: "/nutritionist-queue/:id",
    name: "View Nutritionist Queue",
    component: () => import("@/views/pages/NutritionistQueue/Details.vue"),
    meta: {
      title: "View Nutritionist Queue",
      auth: true,
      guard: {
        action: "view",
        subject: "nutrition_queues",
      },
    },
  },
  {
    path: "/reports/revenue",
    name: "Revenue Report",
    component: () => import("@/views/pages/Reports/RevenueReport/List.vue"),
    meta: {
      auth: true,
      guard: {
        action: "view",
        subject: "reports",
      },
    },
  },
  {
    path: "/reports/collection",
    name: "Collections Report",
    component: () => import("@/views/pages/Reports/CollectionsReport/List.vue"),
    meta: {
      auth: true,
      guard: {
        action: "view",
        subject: "reports",
      },
    },
  },
  {
    path: "/reports/bag-deposit-refund",
    name: "Bag Deposit Refund Report",
    component: () =>
      import("@/views/pages/Reports/BagDepositRefundReport/List.vue"),
    meta: {
      auth: true,
      guard: {
        action: "view",
        subject: "reports",
      },
    },
  },
  {
    path: "/clients/:clientId/orders/:orderId/replace-variations",
    name: "Replace Variations Order",
    component: () =>
      import("@/views/pages/Orders/components/ReplaceOrderVariations.vue"),
    props: true,
    meta: {
      title: "Replace Variations",
      auth: true,
      guard: {
        action: "update",
        subject: "unpaid_order_replace_items",
      },
    },
  },
  {
    path: "/config/meal-templates/replace-variation",
    name: "Replace Variations",
    component: () =>
      import("@/views/pages/Config/MealTemplates/ReplaceVariations.vue"),
    meta: {
      title: "Replace Variations",
      auth: true,
      guard: {
        action: "update",
        subject: "meal_template_replace_items",
      },
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});
router.beforeEach((to, from, next) => {
  document.title =
    process.env.VUE_APP_TITLE + (to.meta.title ? " - " + to.meta.title : "");

  if (store.state.auth.authenticated) {
    let abilities = {};
    if (store.state.auth.user.abilities) {
      abilities = store.state.auth.user.abilities;
    }
    ability.update(abilities);
    if (to.meta.auth) {
      if (
        to.meta.guard &&
        !ability.can(to.meta.guard.action, to.meta.guard.subject)
      ) {
        next({ name: "Error403" });
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    if (to.name !== "SignIn" && to.meta.auth) {
      next({ name: "SignIn" });
    } else {
      next();
    }
  }
});
export default router;
